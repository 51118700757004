import React, { useState, useEffect } from "react";
import Twitter from "./x.webp";
import Github from "./github.svg";
import Gitlab from "./gitlab.webp";
import Lk from "./linkedin.webp";
// import wpoption from "./wp-option.webp";
// import wphook from "./wphooks.webp";
// import captcha from "./captcha.webp";
// import devwp from "./devwp.webp";
import lightening from './lightening2.jpeg';
import hashnode from "./hashnode.png";
import ddt from "./dev.png";
import php from "./php.png";
import resume from "./drive.png";
import LinkCard from './LinkCard';

const links = [
  {
    href: "https://drive.google.com/file/d/17uesQIRe5-oICgWr8owlohqaNn9c6ou9/view?usp=sharing",
    imgSrc: resume,
    imgAlt: "Resume Logo",
    bgColor: "#808080",
    category: "Doc",
    title: "Resume",
    desc: "Take an overview of my expertise in full-stack development & building scalable solutions.",
  },
  {
    href: "http://linkedin.mrmehta.in",
    imgSrc: Lk,
    imgAlt: "LinkedIn Logo",
    bgColor: "#0A66C2",
    category: "Social",
    title: "LinkedIn",
    desc: "Connect with me on LinkedIn to explore my professional journey and discover opportunities in software development.",
  },
  {
    href: "http://x.mrmehta.in",
    imgSrc: Twitter,
    imgAlt: "X Logo",
    bgColor: "#000",
    category: "Social",
    title: "X",
    desc: "Follow me on X to see my thoughts on development trends and the latest in software engineering.",
  },
  {
    href: "https://lightning.ai/mehta",
    imgSrc: lightening,
    imgAlt: "Lightening Logo",
    bgColor: "#6157eb",
    category: "AI",
    title: "Lightening AI",
    desc: "Explore my AI agents built on the Lightning AI, designed to stay ahead in AI and create revolutionary integrations.",
  },
  {
    href: "http://github.mrmehta.in",
    imgSrc: Github,
    imgAlt: "GitHub Logo",
    bgColor: "#6e5494",
    category: "Code",
    title: "GitHub",
    desc: "Check out my GitHub to collaborate on code repositories and explore my contributions to open-source projects.",
  },
  {
    href: "http://gitlab.mrmehta.in",
    imgSrc: Gitlab,
    imgAlt: "GitLab Logo",
    bgColor: "#e2432a",
    category: "Code",
    title: "GitLab",
    desc: "Visit my GitLab to see how I utilize CI/CD pipelines, manage repositories, and streamline DevOps workflows.",
  },
  {
    href: "http://writer.mrmehta.in",
    imgSrc: hashnode,
    imgAlt: "Hashnode Logo",
    bgColor: "#2962FF",
    category: "Blog",
    title: "Hashnode",
    desc: "Read my technical blogs on Hashnode where I share insights and engage with the developer community.",
  },
  {
    href: "http://ddt.mrmehta.in",
    imgSrc: ddt,
    imgAlt: "Dev.to Logo",
    bgColor: "#000",
    category: "Blog",
    title: "Dev.to",
    desc: "Join me on Dev.to to read my articles and participate in discussions with a vibrant community of developers.",
  },
  {
    href: "https://php.godoftitans.tech",
    imgSrc: php,
    imgAlt: "WordPress Logo",
    bgColor: "#21759b",
    category: "Blog",
    title: "WordPress",
    desc: "Explore my WordPress blog to read my articles on web development and software engineering.",
  },
];

// const linksWordPress = [
//   {
//     href: "https://github.com/kartikmehta8/wp-options-visualizer",
//     imgSrc: wpoption,
//     imgAlt: "wp-options-visualizer",
//     bgColor: "#000",
//     category: "Plugin",
//     title: "Dev Tool",
//     desc: "A WordPress plugin to manage, search, add, edit, & delete wp-options directly from the admin panel.",
//   },
//   {
//     href: "https://github.com/kartikmehta8/wp-hooks-visualizer",
//     imgSrc: wphook,
//     imgAlt: "wp-hooks-visualizer",
//     bgColor: "#000",
//     category: "Plugin",
//     title: "Dev Tool",
//     desc: "WP Hooks Visualizer is a WordPress plugin that allows users to easily search and view all registered hooks.",
//   },
//   {
//     href: "https://github.com/kartikmehta8/wp-developer-logs",
//     imgSrc: devwp,
//     imgAlt: "wp-developer-logs",
//     bgColor: "#000",
//     category: "Plugin",
//     title: "Dev Tool",
//     desc: "A WordPress plugin for logging and managing custom developer logs with an AJAX-driven interface.",
//   },
//   {
//     href: "https://github.com/kartikmehta8/wp-captcha",
//     imgSrc: captcha,
//     imgAlt: "wp-captcha",
//     bgColor: "#000",
//     category: "Plugin",
//     title: "Security",
//     desc: "Plugin that adds CAPTCHA to the login page, enhancing security by preventing automated login attempts.",
//   }
// ]

function App() {
  const [theme, setTheme] = useState("light");
  // const [profile, setProfile] = useState(true);

  useEffect(() => {
    document.documentElement.classList.add(theme);
    return () => {
      document.documentElement.classList.remove(theme);
    };
  }, [theme]);

  useEffect(() => {
    const handleDoubleClick = () => {
      setTheme(theme === "light" ? "dark" : "light");
    };

    document.body.addEventListener("dblclick", handleDoubleClick);

    return () => {
      document.body.removeEventListener("dblclick", handleDoubleClick);
    };
  }, [theme]);

  return (
    <div
      className="flex flex-col items-center justify-center bg-gradient-to-r from-blue-100 via-purple-100 to-pink-100 dark:from-gray-900 dark:via-gray-800 dark:to-gray-700 poppins"
      style={{ minHeight: "100vh" }}
    >
      <div className="text-2xl font-extrabold pt-8 text-gray-900 dark:text-gray-100 animate-pulse">Kartik Mehta</div>
      <div className="text-lg flex justify-center mb-4 text-gray-600 dark:text-gray-400">
        <span className="text-center mb-4 mx-4 md:mx-0 lg:mx-0">
          Engineering and Product Development harmoniously united.
        </span>
      </div>
      <div className="flex text-sm text-gray-900 dark:text-gray-100 pb-4">
        {/* <span className={profile ? "mr-2 underline" : "mr-2"} onClick={() => setProfile(true)} style={{cursor: "pointer"}}>profile</span>
        <span className={!profile ? "ml-2 underline" : "ml-2"} onClick={() => setProfile(false)} style={{cursor: "pointer"}}>wordpress</span> */}
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-2">
        {links.map((link, index) => (
          <LinkCard key={index} {...link} />
        ))}
        {/* {!profile && linksWordPress.map((link, index) => (
          <LinkCard key={index} {...link} />
        ))} */}
      </div>
      <div className="flex underline text-xs pt-6 text-gray-900 dark:text-gray-100 pb-4">
        <a href="https://www.tailwine.in" target="_blank" rel="noopener noreferrer">Powered by Tailwine</a>
      </div>
    </div>
  );
}

export default App;
